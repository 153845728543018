/* Vertically and horizontally center the spinner */
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.horizontalCenter {
  margin: 2em 0;
  display: flex;
  justify-content: center;
}

/* SpinKit plane spinner. Source: https://github.com/tobiasahlin/SpinKit */
.sk-plane {
  width: 40px;
  height: 40px;
  background-color: #222;
  animation: sk-plane 1.2s infinite ease-in-out;
}

/* SpinKit plane spinner animation */
@keyframes sk-plane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }

  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }

  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
