/** Styles for the front page */

a {
  color: white;
  text-decoration: underline;
  text-underline-offset: 0.25em;
}

.nav-company-name {
  color: white;
  font-weight: 900;
  text-transform: uppercase;

  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.nav-company-name-container {
  margin: 1em 1em 1em 0;
}

.front-header {
  margin: 1.5em 0;
}

.front-header a {
  /* Links generally have an underline to indicate it is a link except in the
   * header navigation. Looks a bit cleaner that way */
  text-decoration: none;
}

.front-header a:hover {
  /* Include underline on header nav link hover to indicate it is a link */
  text-decoration: underline;
  color: #6e6e6e;
}

.front-header-nav {
  text-align: right;
  margin: 1em;
}

.front-header-nav-item {
  color: white;
  margin-left: 1em;
  margin-right: 1em;
  text-align: right;
}

.brand-name {
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 55px;
  line-height: 55px;
  text-transform: uppercase;
  font-weight: 900;
  margin-top: 15px;
  text-align: center;
  color: white;
}

.brand-name-subtext {
  margin: 2em 2em 0 2em;
  font-size: 20px;
  line-height: 25px;
  font-weight: 300;
  text-align: center;
  color: white;
}

.social-proof {
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 44px;
  line-height: 44px;
  font-weight: 700;
  margin-top: 15px;
  text-align: center;
  color: white;
}

.social-proof-seconds {
  /** To prevent number shifting */
  font-family: monospace;
}

@media (max-width: 767px) {
  .front-header {
    margin: 1em 0;
  }

  .front-header-nav {
    text-align: center;
  }

  .nav-company-name-container {
    text-align: center;
    margin: 0.5em;
  }

  .front-header-nav-item {
    margin: 1em 0.5em;
  }

  .brand-name {
    font-size: 35px;
    line-height: 35px;
  }

  .social-proof {
    font-size: 28px;
    line-height: 28px;
  }
}
