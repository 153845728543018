/* Styles for the < back and X close buttons */
.setupBackCloseContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.setupBackCloseButton {
  font-size: 2em;
  padding: 0.75em;
  color: #000;
}

.setupBackCloseButton:hover {
  color: #414141;
}

.setupBackCloseButton:active {
  color: #6e6e6e;
}

.setupButtonHidden {
  visibility: hidden;
  cursor: auto;
  transition: none;
}

.setupHeading {
  /* Make the heading roughly center along with the back/close buttons */
  margin-top: 0.8em;
}

@media (min-width: 768px) {
  /* Push heading further down on desktops */
  .setupHeading {
    margin-top: 2.25em;
  }
}

@media (max-width: 768px) {
  .setupBackCloseButton {
    padding: 0.75em 0 0;
  }
}
