/*
    The following CSS classes makes the YouTube testimonial videos on the
    frontpage responsive. That is, they make the iframe videos honor the width
    of the parent elements which we define in by column widths in Bootstrap.

    These classes were found here:
    https://github.com/CookPete/react-player#responsive-player

    Previously we used this method when we used YouTube embed iframes directly:
    https://www.h3xed.com/web-development/how-to-make-a-responsive-100-width-youtube-iframe-embed
*/

.video-container {
  /* The aspect ratio of the videos is 16:9, so the
     height should be 9/16 = 0.5625 or 56.25% */
  padding-top: 56.25%;
  position: relative;
}

.video-player {
  position: absolute;
  top: 0;
  left: 0;
}

/* A rectangle in 16:9 aspect ratio with red
   background that houses the play button */
.playButtonContainer {
  background: rgba(219, 32, 26, 0.8);
  width: 125px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.playButtonContainer:hover {
  background: rgba(0, 0, 0, 0.8);
}

/* A triangular play button created with some
   CSS black magic that I don't understand °‿‿° */
.playButton {
  border-style: solid;
  border-width: 16px 0 16px 26px;
  border-color: transparent transparent transparent white;
  margin-left: 7px;
}
