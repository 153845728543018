/*
    I was having some issues with CSS selector priority. The styles from the
    Bootstrap framework got higher priority. I didn't have a lot of time so I
    sort of threw in the towel and just slapped on !important to regain the
    priority. Maybe it can be done in a more proper CSS way.
 */

.cell-center {
  text-align: center !important;
  vertical-align: middle !important;
}

.verticalMiddle {
  vertical-align: middle !important;
}
